import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    Button,
    Paper,
    FormControlLabel,
    Grid,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Typography
} from '@material-ui/core'
import axios from 'axios'
import { API_URL, CODE_DEBUG } from '../../config'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const TicketSettings = (props) => {
    const token = localStorage.getItem('tgToken')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [users, setUsers] = useState([])
    const [visibilityState, setVisibilityState] = useState({})
    const [notificationState, setNotificationState] = useState({})
    const [productionState, setProductionState] = useState({})
    const [salesState, setSalesState] = useState({})
    const [loading, setLoading] = useState(false)

    const getUsers = () => {
        setLoading(true)
        const url = API_URL + '/users/all'
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        axios.get(url, headerConfig).then(response => {
            setUsers(response.data)
            getSelectedUsers()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getSelectedUsers = () => {
        const url = API_URL + '/ticket-settings'
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        axios.get(url, headerConfig).then(response => {
            var visibilityCB = {}
            var notificationCB = {}
            var productionCB = {}
            var salesCB = {}
            response.data.access.map(operation => {
                visibilityCB[operation] = true
                return true
            })
            response.data.emails.map(operation => {
                notificationCB[operation] = true
                return true
            })
            response.data.production.map(operation => {
                productionCB[operation] = true
                return true
            })
            response.data.sales.map(operation => {
                salesCB[operation] = true
                return true
            })
            setVisibilityState({ ...visibilityState, ...visibilityCB })
            setNotificationState({ ...notificationState, ...notificationCB })
            setProductionState({ ...productionState, ...productionCB })
            setSalesState({ ...salesState, ...salesCB })
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        getUsers()
        // eslint-disable-next-line
    }, [])

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        var access = []
        for (var key in visibilityState) {
            if (visibilityState[key] === true) {
                access.push(key)
            }
        }
        var emails = []
        for (key in notificationState) {
            if (notificationState[key] === true) {
                emails.push(key)
            }
        }
        var production = []
        for (key in productionState) {
            if (productionState[key] === true) {
                production.push(key)
            }
        }
        var sales = []
        for (key in salesState) {
            if (salesState[key] === true) {
                sales.push(key)
            }
        }
        const data = {
            access,
            emails,
            production,
            sales
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/ticket-settings'
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Saved.')
            setShowAlert(true)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })

    }

    const handleVisibilityInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setVisibilityState({ ...visibilityState, [name]: value })
    }

    const handleNotificationInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setNotificationState({ ...notificationState, [name]: value })
    }

    const handleProductionInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setProductionState({ ...productionState, [name]: value })
    }

    const handleSalesInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setSalesState({ ...salesState, [name]: value })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Ticket Settings</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item >
                    <Button variant="contained" color="primary" onClick={onSubmit} disabled={loading}>Save</Button>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Paper style={{ padding: "15px" }}>
                <h2>Ticket Visibility</h2>
                <p>Selected users will be able to see tickets.</p>
                <hr />
                <Grid container spacing={3} className="mt-5">
                    {users.map(user => {
                        const name = user.first_name + ' ' + user.last_name
                        return (
                            <Grid key={user.id} item xs={12} sm={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <div>
                                            <input
                                                type="checkbox"
                                                name={user.id}
                                                checked={visibilityState[user.id]}
                                                onChange={handleVisibilityInputChange}
                                            />
                                            &nbsp;
                                        </div>
                                    }
                                    label={name}
                                />
                                {user.email}
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>
            <Paper style={{ padding: "15px" }} className="mt-5">
                <h2>Ticket Notifications</h2>
                <p>Selected users will receive ticket related notifications.</p>
                <hr />
                <Grid container spacing={3} className="mt-5">
                    {users.map(user => {
                        const name = user.first_name + ' ' + user.last_name
                        return (
                            <Grid key={user.id} item xs={12} sm={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <div>
                                            <input
                                                type="checkbox"
                                                name={user.id}
                                                checked={notificationState[user.id]}
                                                onChange={handleNotificationInputChange}
                                            />
                                            &nbsp;
                                        </div>
                                    }
                                    label={name}
                                />
                                {user.email}
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>
            <Paper style={{ padding: "15px" }} className="mt-5">
                <h2>Ticket Production</h2>
                <p>Selected users will be able to view add comment on ticket.</p>
                <hr />
                <Grid container spacing={3} className="mt-5">
                    {users.map(user => {
                        const name = user.first_name + ' ' + user.last_name
                        return (
                            <Grid key={user.id} item xs={12} sm={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <div>
                                            <input
                                                type="checkbox"
                                                name={user.id}
                                                checked={productionState[user.id]}
                                                onChange={handleProductionInputChange}
                                            />
                                            &nbsp;
                                        </div>
                                    }
                                    label={name}
                                />
                                {user.email}
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>
            <Paper style={{ padding: "15px" }} className="mt-5">
                <h2>Ticket Sales</h2>
                <p>Selected users will receive ticket comment related notifications and they will able to see comments added by production users.</p>
                <hr />
                <Grid container spacing={3} className="mt-5">
                    {users.map(user => {
                        const name = user.first_name + ' ' + user.last_name
                        return (
                            <Grid key={user.id} item xs={12} sm={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <div>
                                            <input
                                                type="checkbox"
                                                name={user.id}
                                                checked={salesState[user.id]}
                                                onChange={handleSalesInputChange}
                                            />
                                            &nbsp;
                                        </div>
                                    }
                                    label={name}
                                />
                                {user.email}
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>
        </div>
    )
}

export default TicketSettings
