import React, { useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { API_URL, CODE_DEBUG } from '../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const AddFaq = (props) => {
    const token = localStorage.getItem('tgToken')
    const [title, setTitle] = useState(null)
    const [content, setContent] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)


    const handleTitleChange = (e) => {
        var value = e.target.value
        setTitle(value)
    }

    const handleContentChange = (e) => {
        var value = e.target.value
        setContent(value)
    }

    const trim = (value) => {
        value = ltrim(value)
        value = rtrim(value)
        return value
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            title: trim(title),
            content: trim(content)
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/faqs'
        axios.post(url, data, headerConfig).then(response => {
            props.history.push('/dashboard/faqs')
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/faqs">
                    FAQs
                </MLink>
                <Typography color="textPrimary">Add FAQ</Typography>
            </Breadcrumbs>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="row"
                        >
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="title"
                                    label="Title"
                                    name="title"
                                    inputProps={{ minLength: 3, maxLength: 150 }}
                                    onChange={handleTitleChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    id="content"
                                    label="Content"
                                    name="content"
                                    multiline
                                    rows={15}
                                    variant="outlined"
                                    fullWidth
                                    inputProps={{ minLength: 3, maxLength: 50000 }}
                                    onChange={handleContentChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Link to="/dashboard/faqs">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                >
                                    Add
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default AddFaq
