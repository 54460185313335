import React, { useState, useEffect } from 'react'
import {
    Avatar,
    Breadcrumbs,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../components/Table'
import axios from 'axios'
import { API_URL, CODE_DEBUG } from '../../config'
import VisibilityIcon from '@material-ui/icons/Visibility'
import AddAlertIcon from '@material-ui/icons/AddAlert'
import DeleteIcon from '@material-ui/icons/Delete'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import { Link } from 'react-router-dom'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListCustomer = (props) => {
    const token = localStorage.getItem('tgToken')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [itemId, setItemId] = useState(null)
    const [openNotificationDialog, setOpenNotificationDialog] = useState(false)
    const [openVerificationDialog, setOpenVerificationDialog] = useState(false)
    const [openApprovalDialog, setOpenApprovalDialog] = useState(false)
    const [customerApproval, setCustomerApproval] = useState(false)
    const [customerVerified, setCustomerVerified] = useState(false)
    const [text, setText] = useState('')
    const [openCostDialog, setOpenCostDialog] = useState(false)
    const [maxDays, setMaxDays] = useState(0)
    const [perDay, setPerDay] = useState(0)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "image",
            label: "Image",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <Avatar alt="Profile Image" src={value} />
                }
            }
        },
        {
            name: "first_name",
            label: "First Name",
            options: {
                sort: true,
            }
        },
        {
            name: "last_name",
            label: "Last Name",
            options: {
                sort: true,
            }
        },
        {
            name: "email",
            label: "Email",
            options: {
                sort: true,
            }
        },
        {
            name: "number",
            label: "Number",
            options: {
                sort: true,
            }
        },
        {
            name: "business_name",
            label: "Business Name",
            options: {
                sort: false,
            }
        },
        {
            name: "ticket_count",
            label: "Total Tickets",
            options: {
                sort: false,
            }
        },
        {
            name: "is_verified",
            label: "Verification",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (value) ? (
                        <p className="verified">
                            Verified
                        </p>
                    ) : (
                        <p className="un-verified">
                            Un-Verified
                        </p>
                    )
                }
            }
        },
        {
            name: "is_approved",
            label: "User Approval",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return (value) ? (
                        <p className="verified">
                            <DoneIcon />
                        </p>
                    ) : (
                        <p className="un-verified">
                            <CloseIcon />
                        </p>
                    )
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = API_URL + '/customers'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const viewUrl = 'customers/' + item.id
                const emailUrl = 'mailto:' + item.email
                const numberUrl = 'tel:' + item.number
                const email = (
                    <Tooltip title="Send Email">
                        <MLink href={emailUrl} className="link" color="inherit">
                            {item.email}
                        </MLink>
                    </Tooltip>
                )
                const number = (
                    <Tooltip title="Call">
                        <MLink href={numberUrl} className="link" color="inherit">
                            {item.number}
                        </MLink>
                    </Tooltip>
                )
                const count = (
                    <Tooltip title="View Tickets">
                        <Link to={viewUrl} className="link">
                            <b>{item.ticket_count}</b>
                        </Link>
                    </Tooltip>
                )
                const action = (
                    <div>
                        <Link to={viewUrl}>
                            <Tooltip title="View Details">
                                <IconButton>
                                    <VisibilityIcon />
                                </IconButton>
                            </Tooltip>
                        </Link>
                        <Tooltip title="Additional Cost">
                            <IconButton onClick={() => handleClickOpenCostDialog(item.id, item.max_day_allowed, item.per_day_charge)}>
                                <AttachMoneyIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Send Notification">
                            <IconButton onClick={() => handleClickOpenNotificationDialog(item.id)}>
                                <AddAlertIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Approve User">
                            <IconButton onClick={() => handleClickOpenApprovalDialog(item)}>
                                <ThumbUpAltIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Verify User">
                            <IconButton onClick={() => handleClickOpenVerificationDialog(item)}>
                                <DoneAllIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete Customer">
                            <IconButton onClick={() => handleClickOpen(item.id)}>
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                )
                if (item.business_id !== '') {
                    const businessUrl = 'business/' + item.business_id
                    const business = (
                        <Tooltip title="View Business">
                            <Link to={businessUrl} className="link">
                                {item.business_name}
                            </Link>
                        </Tooltip>
                    )
                    item.business_name = business
                }
                item.email = email
                item.number = number
                item.ticket_count = count
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const deleteItem = (itemId) => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/customers/' + itemId
        axios.delete(url, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Deleted.')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            var message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 409) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    const sendNotification = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            id: itemId,
            message: text
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/customers/notification'
        axios.post(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Sent.')
            setShowAlert(true)
            setLoading(false)
            handleClose()
        }).catch(error => {
            var message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 404) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const updateApproval = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            is_approved: customerApproval
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/customers/' + itemId + '/approval'
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('User Approved')
            setShowAlert(true)
            setLoading(false)
            handleClose()
        }).catch(error => {
            var message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 404) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const updateVerification = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            is_verified: customerVerified
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/customers/' + itemId + '/verification'
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Updated Successfully')
            setShowAlert(true)
            setLoading(false)
            handleClose()
        }).catch(error => {
            var message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 404) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const updateCost = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            max_day_allowed: maxDays,
            per_day_charge: perDay
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/customers/' + itemId + '/cost'
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Updated.')
            setShowAlert(true)
            setLoading(false)
            getList()
            handleClose()
        }).catch(error => {
            var message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 404) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleTextChange = (e) => {
        const value = e.target.value
        setText(value)
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (itemId) => {
        setOpen(true)
        setItemId(itemId)
    }

    const handleClickOpenNotificationDialog = (itemId) => {
        setOpenNotificationDialog(true)
        setItemId(itemId)
    }

    const handleClickOpenApprovalDialog = (item) => {
        setOpenApprovalDialog(true)
        setItemId(item.id)
        setCustomerApproval(item.is_approved)
    }

    const handleClickOpenVerificationDialog = (item) => {
        setOpenVerificationDialog(true)
        setItemId(item.id)
        setCustomerVerified(item.is_verified)
    }

    const handleClickOpenCostDialog = (itemId, maxDays, perDay) => {
        setOpenCostDialog(true)
        setItemId(itemId)
        setMaxDays(maxDays)
        setPerDay(perDay)
    }

    const handleClose = () => {
        setOpen(false)
        setOpenNotificationDialog(false)
        setOpenVerificationDialog(false)
        setOpenApprovalDialog(false)
        setItemId(null)
        setText('')
        setMaxDays(0)
        setPerDay(0)
        setOpenCostDialog(false)
        getList(start, limit, sortBy, order, search)
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Customers</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Customer</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete the Customer?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(itemId)} disabled={loading} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={openNotificationDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={sendNotification}>
                    {progress}
                    <DialogTitle id="alert-dialog-title">Send Notification</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label="Notification Content"
                                required
                                multiline
                                rows={4}
                                inputProps={{ minLength: 3, maxLength: 150 }}
                                variant="outlined"
                                onChange={handleTextChange}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" autoFocus>
                            Close
                        </Button>
                        <Button variant="outlined" type="submit" disabled={loading}>
                            Send
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                fullWidth
                open={openApprovalDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={updateApproval}>
                    {progress}
                    <DialogTitle id="alert-dialog-title">Approve User</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={customerApproval}
                                        onChange={(e) => setCustomerApproval(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Is Approved"
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" autoFocus>
                            Close
                        </Button>
                        <Button variant="outlined" type="submit" disabled={loading}>
                            Update
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>

            <Dialog
                fullWidth
                open={openVerificationDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={updateVerification}>
                    {progress}
                    <DialogTitle id="alert-dialog-title">Verify Customer</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={customerVerified}
                                        onChange={(e) => setCustomerVerified(e.target.checked)}
                                        color="primary"
                                    />
                                }
                                label="Is Verified"
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" autoFocus>
                            Close
                        </Button>
                        <Button variant="outlined" type="submit" disabled={loading}>
                            Update
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                fullWidth
                open={openCostDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={updateCost}>
                    {progress}
                    <DialogTitle id="alert-dialog-title">Additional Cost</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label="Maximum Days Allowed"
                                required
                                type="number"
                                inputProps={{ min: 0, max: 100000000 }}
                                variant="outlined"
                                value={maxDays}
                                onChange={(e) => setMaxDays(e.target.value)}
                            />
                            <br />
                            <br />
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label="Per Day Charge"
                                required
                                type="number"
                                inputProps={{ min: 0, max: 100000000 }}
                                variant="outlined"
                                value={perDay}
                                onChange={(e) => setPerDay(e.target.value)}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" autoFocus>
                            Close
                        </Button>
                        <Button variant="outlined" type="submit" disabled={loading}>
                            Send
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {progress}
            <Table
                title={'List Customers'}
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
        </div>
    )
}

export default ListCustomer
