import React, { useState, useEffect } from 'react'
import {
    Avatar,
    Breadcrumbs,
    ButtonGroup,
    TextField,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../components/Table'
import { Autocomplete } from '@material-ui/lab'
import { Link } from 'react-router-dom'
import { apiGet,apiPut } from '../../functionsAPI'
import { checkDateIsExpired, dateFormate, dateTimeFormate, trolleyStatus } from '../../functions'
import MuiAlert from '@material-ui/lab/Alert'
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const CheckOut = (props) => {
    const user = JSON.parse(localStorage.getItem('tgUser'))
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const [plants,setPlants] = useState([])
    const [currentPlant,setCurrentPlant] = useState(null)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "trolley_photo_url",
            label: "Image",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    return <Avatar alt="Trolley Image" src={value} />
                }
            }
        },
        {
            name: "name",
            label: "Trolley Name",
            options: {
                sort: false,
            }
        },
        {
            name: "uid",
            label: "UID",
            options: {
                sort: true,
            }
        },
        {
            name: "customer",
            label: "Customer Name",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    let item = null
                    if (value) {
                        const customerUrl = '/dashboard/customers/' + value.id
                        item = (
                            <Tooltip title="View Customer">
                                <Link to={customerUrl} className="link">
                                    {value.first_name + ' ' + value.last_name}
                                </Link>
                            </Tooltip>
                        )
                    } else {
                        item = 'At ToughGlass'
                    }
                    return item
                }
            }
        },
        {
            name: "trolley_status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: trolleyStatus
            }
        },
        {
            name: "serial_number",
            label: "Serial Number",
            options: {
                sort: false,
            }
        },
        {
            name: "is_certified",
            label: "Certified",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    const item = value ? (
                        <>
                            <FiberManualRecordIcon style={{ color: 'green', paddingTop: '6px' }} /> Yes
                        </>
                    ) : 'No'
                    return item
                }
            }
        },
        {
            name: "expiry_date",
            label: "Expiry Date",
            options: {
                sort: false,
                customBodyRender: (value) => {
                    let item = ''
                    if (value) {
                        const isExpired = checkDateIsExpired(value)
                        const date = dateFormate(value)
                        if (isExpired) {
                            item = (
                                <>
                                    <FiberManualRecordIcon style={{ color: 'red', paddingTop: '6px' }} /> {date}
                                </>
                            )
                        } else {
                            item = date
                        }
                    }
                    return item
                }
            }
        },
        {
            name: "created_at",
            label: "Create Date",
            options: {
                sort: true,
                customBodyRender: dateTimeFormate
            }
        },
        {
            name: "created_at",
            label: "Last Update",
            options: {
                sort: true,
                customBodyRender: dateTimeFormate
            }
        },
        {
            name: "from_plant_name",
            label: "From Plant",
            options: {
                sort: false,
            }
        },
        {
            name: "to_plant_name",
            label: "To Plant",
            options: {
                sort: false,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all', _plant) => {
        setLoading(true)
        if(_plant){
            const params = {
                sort_by: sortBy,
                order,
                start,
                limit,
                search,
                plant_id:_plant ? _plant.plant.id : null,
                trolley_status: 'PlantChange',
                disabled: 0
            }
            const url = '/trolleys/statuses/checkout'
            apiGet(url, params).then(response => {
                response.data.list.map(item => {
                    const action = (
                        <ButtonGroup>
                            <Tooltip title="Checkout" onClick={()=>handleCheckout(item, _plant)}>
                                <IconButton>
                                    <LocalShippingIcon />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    )
                    item.action = action
                    return true
                })
                const data = []
                response.data.list.map(item=>{
                    const found = item.trolley_status === "PlantChange";
                    if (found) return data.push(item);
                    else return null
                })
                setTableData(data)
                setCount(data.length)
                setLoading(false)
            }).catch(error => {
                console.log(error)
                setAlertType('error')
                setAlertMessage(error)
                setShowAlert(true)
                setLoading(false)
            })
        }else{
            setTableData([])
            setAlertType('error')
            setAlertMessage('Please Select Plant')
            setShowAlert(true)
            setLoading(false)
        }
    }

    const handleCheckout = (trolley, _plant) => {
        const data = {
            name : null,
            no_of_bars: 0,
            images: [],
            image: null,
            note: null,
            to_plant_id: trolley.to_plant,
            from_plant_id: trolley.from_plant,
        }
        const url = '/trolleys/' + trolley.id + '/plantdropoff'
        apiPut(url, data).then(response => {
            setAlertType('success')
            setAlertMessage('Updated.')
            setShowAlert(true)
            setLoading(false)
            getList(null,null,null,null,null,_plant)
            // props.history.push('/dashboard/trolleys')
        }).catch(error => {
            console.log(error)
            setAlertType('error')
            setAlertMessage(error)
            setShowAlert(true)
            setLoading(false)
        })   
    }

    const getPlants = () => {
        setLoading(true)
        const url =  `/users/${user.id}/user-plant`
        apiGet(url)
            .then((response) => {
                if (response.data.length !== 0 ){
                    setPlants(response.data)
                    if(!currentPlant){
                        setCurrentPlant(response.data[0]);
                    }
                    getList(null,null,null,null,null,currentPlant)
                }else{
                    setAlertType('error')
                    setAlertMessage('You don\'t have permission to any plant')
                    setShowAlert(true)
                }
                setLoading(false)
            })
            .catch(error => {
                console.log(error)
                setAlertType('error')
                setAlertMessage(error)
                setShowAlert(true)
                setLoading(false)
            })
    }

    useEffect(() => {
        getPlants()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search, currentPlant)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search, currentPlant)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search, currentPlant)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search, currentPlant)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <Typography color="textPrimary">Trolley Checkout</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Autocomplete
                        disableClearable
                        options={plants}
                        value={currentPlant}
                        autoHighlight
                        getOptionLabel={(option) => option.plant.name}
                        renderInput={(params) => <TextField
                            {...params}
                            label="plant"
                            variant="outlined"
                        />}
                        onChange={(event, value) => {setCurrentPlant(value);getList(null,null,null,null,null,value)}}
                    />
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Table
                title={'List Trolley'}
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
        </div>
    )
}

export default CheckOut