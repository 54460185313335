import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    Button,
    Paper,
    FormControlLabel,
    Grid,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Typography
} from '@material-ui/core'
import axios from 'axios'
import { API_URL, CODE_DEBUG } from '../../config'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const BusinessTicketAccess = (props) => {
    const businessId = props.match.params.businessId
    const token = localStorage.getItem('tgToken')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [customers, setCustomers] = useState([])
    const [visibilityState, setVisibilityState] = useState({})
    const [notificationState, setNotificationState] = useState({})
    const [appNotificationState, setAppNotificationState] = useState({})
    const [loading, setLoading] = useState(false)
    const [business, setBusiness] = useState({})

    const getCustomers = () => {
        setLoading(true)
        const url = API_URL + '/business/' + businessId + '/customers/all'
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        axios.get(url, headerConfig).then(response => {
            setCustomers(response.data)
            getSelectedCustomers()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getSelectedCustomers = () => {
        const url = API_URL + '/business/' + businessId + '/customers/settings'
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        axios.get(url, headerConfig).then(response => {
            var visibilityCB = {}
            var notificationCB = {}
            var appNotificationCB = {}
            response.data.access.map(operation => {
                visibilityCB[operation] = true
                return true
            })
            response.data.emails.map(operation => {
                notificationCB[operation] = true
                return true
            })
            response.data.notifications.map(operation => {
                appNotificationCB[operation] = true
                return true
            })
            setVisibilityState({ ...visibilityState, ...visibilityCB })
            setNotificationState({ ...notificationState, ...notificationCB })
            setAppNotificationState({ ...appNotificationState, ...appNotificationCB })
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const getBusiness = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/business/' + businessId
        axios.get(url, headerConfig).then(response => {
            const business = response.data
            setBusiness(business)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    useEffect(() => {
        getCustomers()
        getBusiness()
        // eslint-disable-next-line
    }, [])

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        var access = []
        for (var key in visibilityState) {
            if (visibilityState[key] === true) {
                access.push(key)
            }
        }
        var emails = []
        for (key in notificationState) {
            if (notificationState[key] === true) {
                emails.push(key)
            }
        }
        var notifications = []
        for (key in appNotificationState) {
            if (appNotificationState[key] === true) {
                notifications.push(key)
            }
        }
        const data = {
            access,
            emails,
            notifications
        }

        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/business/' + businessId + '/customers/settings'
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Saved.')
            setShowAlert(true)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })

    }

    const handleVisibilityInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setVisibilityState({ ...visibilityState, [name]: value })
    }

    const handleNotificationInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setNotificationState({ ...notificationState, [name]: value })
    }

    const handleAppNotificationInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setAppNotificationState({ ...appNotificationState, [name]: value })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <MLink color="inherit" href="/#/dashboard/business">
                            Business
                        </MLink>
                        <Typography color="textPrimary">Business Ticket Settings - {business.name}</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item >
                    <Button variant="contained" color="primary" onClick={onSubmit} disabled={loading}>Save</Button>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Paper style={{ padding: "15px" }}>
                <h2>Ticket Visibility</h2>
                <p>Selected employees will be able to see tickets.</p>
                <hr />
                <Grid container spacing={3} className="mt-1">
                    {customers.map(item => {
                        const name = item.first_name + ' ' + item.last_name
                        return (
                            <Grid key={item.id} item xs={12} sm={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <div>
                                            <input
                                                type="checkbox"
                                                name={item.id}
                                                checked={visibilityState[item.id]}
                                                onChange={handleVisibilityInputChange}
                                            />
                                            &nbsp;
                                        </div>
                                    }
                                    label={name}
                                />
                                {item.email}
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>
            <Paper style={{ padding: "15px" }} className="mt-5">
                <h2>Ticket Email</h2>
                <p>Selected employees will receive ticket related emails.</p>
                <hr />
                <Grid container spacing={3} className="mt-1">
                    {customers.map(item => {
                        const name = item.first_name + ' ' + item.last_name
                        return (
                            <Grid key={item.id} item xs={12} sm={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <div>
                                            <input
                                                type="checkbox"
                                                name={item.id}
                                                checked={notificationState[item.id]}
                                                onChange={handleNotificationInputChange}
                                            />
                                            &nbsp;
                                        </div>
                                    }
                                    label={name}
                                />
                                {item.email}
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>
            <Paper style={{ padding: "15px" }} className="mt-5">
                <h2>Ticket Notifications</h2>
                <p>Selected employees will receive ticket related notifications on mobile devices.</p>
                <hr />
                <Grid container spacing={3} className="mt-1">
                    {customers.map(item => {
                        const name = item.first_name + ' ' + item.last_name
                        return (
                            <Grid key={item.id} item xs={12} sm={12} md={3}>
                                <FormControlLabel
                                    control={
                                        <div>
                                            <input
                                                type="checkbox"
                                                name={item.id}
                                                checked={appNotificationState[item.id]}
                                                onChange={handleAppNotificationInputChange}
                                            />
                                            &nbsp;
                                        </div>
                                    }
                                    label={name}
                                />
                                {item.email}
                            </Grid>
                        )
                    })}
                </Grid>
            </Paper>
        </div>
    )
}

export default BusinessTicketAccess
