import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.toughglass.com.au/">
                2015 - {new Date().getFullYear()} ToughGlass
            </Link>
            {' '}|{' '}
            <Link color="inherit" href="/">
                Home
            </Link>
        </Typography>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
}))

export default function PrivacyPolicy() {
    const classes = useStyles()

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Container component="main" className={classes.main} maxWidth="lg">
                <Typography variant="h2" component="h1" gutterBottom>
                    Privacy Policy
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    We collect information
                </Typography>
                <Typography variant="body1" align="justify">
                    We use technologies like cookies (small files stored by your browser), web beacons, or unique device identifiers to anonymously identify your computer or device so we can deliver a better experience. Our systems also log information like your browser, operating system and IP address.
                    We also collect personally identifiable information about you. In other words, we also collect information such as your name, address, phone number or email address.
                    We also knowingly contact or collect personal information from children under 13. If you believe we have inadvertently collected such information, please contact us so we can promptly obtain parental consent or remove the information.
                    Our service does not currently recognize the “Do Not Track” signal that may be available in some web browsers.
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    How we collect Personally identifiable information
                </Typography>
                <Typography variant="body1" align="justify">
                    When you register we may collect a variety of information, including your name, mailing address, email address.
                    We may use cookies to identify that you’ve logged in to the Services. Although most browsers automatically accept cookies, you can change your browser options to stop automatically accepting cookies or to prompt you before accepting cookies. Please note, however, that if you don’t accept cookies, you will not be able to stay automatically logged in to the Services.
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    How we use your Personally identifiable information
                </Typography>
                <Typography variant="body1" align="justify">
                    The personal information we collect allows us to keep you posted on Arkay Apps latest Services and updates. Arkay Apps and its affiliates may share personal information with each other and use it consistent with this Privacy Policy. They may also combine it with other information to provide and improve our Services.
                    We also use personal information to help us develop, deliver, and improve our Services. From time to time, we may use your Personally identifiable information to send important notices, such as communications about changes to our terms, conditions, and policies. If you don’t want to be on our mailing list, you can opt out anytime by unregistering at info@arkayapps.com We may also use Personally identifiable information for internal purposes such as auditing, data analysis, and research to improve Arkay Apps Services and customer communications.
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    We may collect and share anonymous location data.
                </Typography>
                <Typography variant="body1" align="justify">
                    To customize our service for you, we and our partners may collect, use, and share precise location data, including the real-time geographic location of your computer or device. This location data is collected anonymously in a form that does not personally identify you and is used only to provide and improve our service. We may obtain your consent on your first use of the service.
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    You can request to see your personal data.
                </Typography>
                <Typography variant="body1" align="justify">
                    We do not permit direct association with any specific individual.
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    We don’t share your personal data with other companies.
                </Typography>
                <Typography variant="body1" align="justify">
                    We do not store any of your personal information
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    You can ask privacy questions.
                </Typography>
                <Typography variant="body1" align="justify">
                    If you have any questions or concerns about our privacy policies, please contact sale@toughglass.com.au
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    Service providers access data on our behalf.
                </Typography>
                <Typography variant="body1" align="justify">
                    In order to serve you, we may share your personal and anonymous information with other companies, including vendors and contractors. Their use of information is limited to these purposes, and subject to agreements that require them to keep the information confidential. Our vendors provide assurance that they take reasonable steps to safeguard the data they hold on our behalf, although data security cannot be guaranteed.
                    Analytics companies may access anonymous data (such as your IP address or device ID) to help us understand how our services are used. They use this data solely on our behalf. They do not share it except in aggregate form no data is shared as to any individual user. Click to see company privacy policies that govern their use of data.
                    Google Analytics
                    Google Analytics is a web analytics tool that helps website owners understand how visitors engage with their website. Google Analytics customers can view a variety of reports about how visitors interact with their website so they can improve it. Google Analytics collects information anonymously. It reports website trends without identifying individual visitors.<br />
                    <Link href="http://www.google.com/analytics/learn/privacy.html" target="_blank">Privacy Policy and Choices</Link>
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    We take steps to protect personal information
                </Typography>
                <Typography variant="body1" align="justify">
                    We store any of your personal information
                    Information we collect may be stored or processed on computers located in any country where we do business.
                    we cannot guarantee to protect your information from hackers or human error.
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    Special situations may require disclosure of your data.
                </Typography>
                <Typography variant="body1" align="justify">
                    To operate the service, we also may make identifiable and anonymous information available to third parties in these limited circumstances: (1) with your express consent, (2) when we have a good faith belief it is required by law, (3) when we have a good faith belief it is necessary to protect our rights or property, or (4) to any successor or purchaser in a merger, acquisition, liquidation, dissolution or sale of assets. Your consent will not be required for disclosure in these cases, but we will attempt to notify you, to the extent permitted by law to do so.
                </Typography>
                <br />
                <Typography variant="h5" component="h2" gutterBottom>
                    You can review more privacy-related information.
                </Typography>
                <Typography variant="body1" align="justify">
                    This privacy policy was last updated on [08/04/15]. Our privacy policy may change from time to time. If we make any material changes to our policies, we will place a prominent notice on our website or application. If the change materially affects registered users, we will send a notice to you by email, push notification or text. <br />
                    Contact Us or email Us at sales@@toughglass.com.au any query regarding privacy policy.
                </Typography>
            </Container>
            <footer className={classes.footer}>
                <Container maxWidth="sm">
                    <Copyright />
                </Container>
            </footer>
        </div>
    )
}
