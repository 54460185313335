import React, { useEffect, useState } from 'react'
import {
    Breadcrumbs,
    Card,
    CardContent,
    Chip,
    Grid,
    LinearProgress,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { dateFormate } from '../functions'
import axios from 'axios'
import { API_URL, CODE_DEBUG } from '../config'
import TableLocal from '../components/TableLocal'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Dashboard = () => {
    const token = localStorage.getItem('tgToken')
    const [businesses, setBusinesses] = useState(0)
    const [customers, setCustomers] = useState(0)
    const [tickets, setTickets] = useState(0)
    const [tableData, setTableData] = useState([])
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return rowIndex + 1
                }
            }
        },
        {
            name: "ticket_id",
            label: "Ticket ID",
            options: {
                sort: true,
            }
        },
        {
            name: "created_at",
            label: "Ticket Received Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "glass_order_no",
            label: "Glass Order No",
            options: {
                sort: true,
            }
        },
        {
            name: "customer_po_no",
            label: "Customer PO No",
            options: {
                sort: true,
            }
        },
        {
            name: "glass_type",
            label: "Glass Type",
            options: {
                sort: false,
            }
        },
        {
            name: "warranty_reason",
            label: "Warranty Reason",
            options: {
                sort: false,
            }
        },
        {
            name: "glass_size",
            label: "Glass Size",
            options: {
                sort: true,
            }
        },
        {
            name: "panel_status",
            label: "Panel Status",
            options: {
                sort: false,
            }
        },
        {
            name: "received_date",
            label: "Glass Received Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "expected_date",
            label: "Expected Replacement Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "business_name",
            label: "Business",
            options: {
                sort: false,
            }
        },
        {
            name: "name",
            label: "Customer",
            options: {
                sort: false,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let status
                    if (value === 'Created') {
                        status = <Chip label={value} style={{ backgroundColor: "#FFF6E2", color: "orange" }} />
                    } else if (value === 'Notified') {
                        status = <Chip label={value} style={{ backgroundColor: "#dae9f2", color: "#0c69a9" }} />
                    } else if (value === 'Delivered') {
                        status = <Chip label={value} style={{ backgroundColor: "#E2FAED", color: "#39D980" }} />
                    } else {
                        status = value
                    }
                    return status
                }
            }
        }
    ]

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/dashboard'
        axios.get(url, headerConfig).then(response => {
            const data = response.data
            setBusinesses(data.businesses)
            setCustomers(data.customers)
            setTickets(data.tickets)
            setLoading(false)
        }).catch(error => {
            var message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(false)
            setLoading(false)
        })
    }

    const getList = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/tickets/recent'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const viewUrl = '/dashboard/tickets/' + item.id
                const customerUrl = '/dashboard/customers/' + item.customer_id
                const ticket_id = (
                    <Tooltip title="View Ticket">
                        <Link to={viewUrl} className="link">
                            {item.ticket_id}
                        </Link>
                    </Tooltip>
                )
                const customer = (
                    <Tooltip title="View Customer">
                        <Link to={customerUrl} className="link">
                            {item.name}
                        </Link>
                    </Tooltip>
                )
                if (item.business_id !== '') {
                    const businessUrl = '/dashboard/business/' + item.business_id
                    const business = (
                        <Tooltip title="View Business">
                            <Link to={businessUrl} className="link">
                                {item.business_name}
                            </Link>
                        </Tooltip>
                    )
                    item.business_name = business
                }
                item.ticket_id = ticket_id
                item.name = customer
                return true
            })
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
        getList()
        // eslint-disable-next-line
    }, [])

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography color="textPrimary">Dashboard</Typography>
            </Breadcrumbs>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Grid container spacing={3}>
                <Grid item xs={12} sm={4} md={4}>
                    <Link to="dashboard/business">
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Total Businesses
                                </Typography>
                                <Typography className="dc-count" variant="h5" component="h2">
                                    {businesses}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Link to="dashboard/customers">
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Total Customers
                                </Typography>
                                <Typography className="dc-count" variant="h5" component="h2">
                                    {customers}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
                <Grid item xs={12} sm={4} md={4}>
                    <Link to="dashboard/tickets">
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    Total Tickets
                                </Typography>
                                <Typography className="dc-count" variant="h5" component="h2">
                                    {tickets}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Link>
                </Grid>
            </Grid>
            <br />
            <TableLocal
                title={'Recent Tickets'}
                columns={columns}
                data={tableData}
            />
        </div>
    )
}

export default Dashboard
