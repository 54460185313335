import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { API_URL, CODE_DEBUG } from '../../config'
import { Autocomplete } from '@material-ui/lab'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UpdateFaq = (props) => {
    const businessId = props.match.params.businessId
    const token = localStorage.getItem('tgToken')
    const [name, setName] = useState('')
    const [accountId, setAccountId] = useState('')
    const [customer, setCustomer] = useState(null)
    const [number, setNumber] = useState('')
    const [address, setAddress] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [customers, setCustomers] = useState([])

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/business/' + businessId
        axios.get(url, headerConfig).then(response => {
            const business = response.data
            setName(business.name)
            setAccountId(business.account_id)
            customers.map((item) => {
                const found = business.email === item.email;
                if (found) return setCustomer(item);
                else return null
            })
            setNumber(business.contact)
            setAddress(business.address)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getCustomers()
        // eslint-disable-next-line
    }, [])

    const handleNameChange = (e) => {
        var value = e.target.value
        setName(value)
    }

    const handleAccountIdChange = (e) => {
        var value = e.target.value
        setAccountId(value)
    }

    const handleNumberChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setNumber(value)
    }

    const handleAddressChange = (e) => {
        var value = e.target.value
        setAddress(value)
    }

    const trim = (value) => {
        value = ltrim(value)
        value = rtrim(value)
        return value
    }

    useEffect(() => {
        if (customers.length > 0) {
            getData()
        }
        // eslint-disable-next-line
    }, [customers])

    const getCustomers = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/customers/all'
        axios.get(url, headerConfig).then(response => {
            setCustomers(response.data)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            name: trim(name),
            account_id: accountId ? trim(accountId) : null,
            customer_id: customer.id,
            contact: number,
            address: trim(address),
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/business/' + businessId
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Business updated.')
            setShowAlert(true)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 409) {
                    message = error.response.data.detail
                } else if (error.response.status === 422) {
                    if (error.response.data.detail.length > 0) {
                        message = error.response.data.detail[0].loc[1] + ' ' + error.response.data.detail[0].msg
                    } else {
                        message = error.response.data.detail.msg
                    }
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/business">
                    Business
                </MLink>
                <Typography color="textPrimary">Update Business</Typography>
            </Breadcrumbs>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid item xs={12} md={6} lg={4} className="mt-5">
                            <TextField
                                id="name"
                                name="name"
                                label="Name"
                                variant="outlined"
                                inputProps={{ minLength: 3, maxLength: 255 }}
                                value={name}
                                onChange={handleNameChange}
                                fullWidth
                                required />
                            <br /><br />
                            <TextField
                                id="accountId"
                                name="accountId"
                                label="Account ID"
                                variant="outlined"
                                inputProps={{ minLength: 1, maxLength: 5 }}
                                value={accountId}
                                onChange={handleAccountIdChange}
                                fullWidth
                            />
                            <br /><br />
                            <Autocomplete
                                disableClearable
                                options={customers}
                                required
                                getOptionLabel={(option) => option.first_name + ' ' + option.last_name + ' – ' + option.email}
                                renderInput={(params) => <TextField {...params} label="Email" variant="outlined" required />}
                                onChange={(e, value) => { setCustomer(value) }}
                                value={customer}
                            />
                            <br />
                            <TextField
                                id="contactNo"
                                name="contactNo"
                                label="Contact No"
                                variant="outlined"
                                inputProps={{ minLength: 10, maxLength: 13, title: "Numbers only", pattern: "*[0-9]" }}
                                value={number}
                                onChange={handleNumberChange}
                                fullWidth
                                required />
                            <br /><br />
                            <TextField
                                id="address"
                                name="address"
                                label="Address"
                                variant="outlined"
                                rows={5}
                                inputProps={{ minLength: 3, maxLength: 255 }}
                                value={address}
                                onChange={handleAddressChange}
                                fullWidth
                                required />
                            <br /><br /><br />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Link to="/dashboard/business"><Button variant="contained">Cancel</Button></Link>
                            <Button variant="contained" color="primary" className="ml-2" type="submit" disabled={loading}>
                                Update
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default UpdateFaq
