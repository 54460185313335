import './App.css'
import './assets/css/style.css'
import React, { useEffect, useState } from 'react'
import { HashRouter as Router, Switch, Route } from 'react-router-dom'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Layout from './components/Layout'
import Login from './pages/authentication/Login'
import ForgotPassword from './pages/authentication/ForgotPassword'
import PrivacyPolicy from './pages/PrivacyPolicy'
import Support from './pages/Support'

function App() {
    const [darkState, setDarkState] = useState(false)
    const palletType = darkState ? 'dark' : 'light'

    const primaryDark = '#0C69A9'
    const primaryLight = '#0C69A9'
    const mainPrimaryColor = darkState ? primaryDark : primaryLight

    const secondaryDark = '#FF740A'
    const secondaryLight = '#FF740A'
    const mainSecondaryColor = darkState ? secondaryDark : secondaryLight

    const darkTheme = createMuiTheme({
        palette: {
            type: palletType,
            primary: {
                main: mainPrimaryColor
            },
            secondary: {
                main: mainSecondaryColor
            }
        }
    })

    useEffect(() => {
        const darkState = localStorage.getItem('darkState')
        if (darkState === 'light') {
            setDarkState(false)
        } else {
            setDarkState(true)
        }
        // eslint-disable-next-line
    }, [])

    const handleThemeChange = () => {
        setDarkState(!darkState)
        const palletType = !darkState ? 'dark' : 'light'
        localStorage.setItem('darkState', palletType)
    }

    return (
        <ThemeProvider theme={darkTheme}>
            <div className="noSelect">
                <Router>
                    <Route path='/login' component={Login} />
                    <Route path='/privacy-policy' component={PrivacyPolicy} />
                    <Route path='/support' component={Support} />
                    <Switch>
                        <Route exact path='/' render={(props) => {
                            const token = localStorage.getItem('tgToken')
                            if (token !== null) {
                                props.history.push('/dashboard')
                            }
                            else {
                                props.history.push('/login')
                            }
                        }} />
                        <Route path='/forgot' component={ForgotPassword} />
                        <Route
                            path='/dashboard'
                            render={(props) => (
                                <Layout {...props} handleThemeChange={handleThemeChange} darkState={darkState} />
                            )}
                        />
                    </Switch>
                </Router>
            </div>
        </ThemeProvider>
    )
}

export default App
