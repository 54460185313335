import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Button,
    Grid,
    TextField
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { API_URL, CODE_DEBUG } from '../../../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const Preferences = () => {
    const token = localStorage.getItem('tgToken')
    const [id, setId] = useState("");
    const [email, setEmail] = useState('')
    const [disabled, setDisabled] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')


    const getEmail = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/notification-settings'
        axios.get(url, headerConfig).then(response => {
            const user = response.data
            setId(user.id);
            setEmail(user.email ? user.email : "")
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                } else {
                    CODE_DEBUG && console.log(error.response)
                }
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
        })
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setDisabled(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const data = {
            email: email
        }
        const url = API_URL + '/notification-settings/' + id
        axios.put(url, data, headerConfig).then(response => {
            // user.first_name = firstName
            console.log(response.data, "%%%%%%%%")
            // localStorage.setItem('tgUser', JSON.stringify(user))
            setAlertType('success')
            setAlertMessage('Profile updated.')
            setShowAlert(true)
            setDisabled(false)
        }).catch(error => {
            let message
            if (error.response) {
                message = 'Try after some time.'
                if (error.response.status === 401) {
                    setAlertType('error')
                    message = error.response.data.detail
                    setShowAlert(true)
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
                message = 'Try after some time.'
            } else {
                CODE_DEBUG && console.log(error)
                message = 'Try after some time.'
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setDisabled(false)
        })
    }
    const handleEmailChange = (e) => {
        var value = e.target.value
        value = ltrim(value)
        value = rtrim(value)
        setEmail(value)
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    useEffect(() => {
        getEmail()
        // eslint-disable-next-line
    }, [])

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Card>
                <CardContent>
                    <form id="pro" name="pro" onSubmit={onSubmit}>
                        <Grid
                            container
                            spacing={3}
                            direction="column"
                        >
                            <p style={{ fontSize: '1rem', marginLeft: '1rem' }}>Customer Registration Email Alert</p>
                            <Grid item xs={6} className="mt-3">
                                <TextField
                                    defaultValue={email}
                                    placeholder='Email'
                                    id="email"
                                    name="email"
                                    variant="outlined"
                                    inputProps={{ minLength: 5, maxLength: 100, type: 'email' }}
                                    onChange={handleEmailChange}
                                    fullWidth
                                    value={email}
                                    required />
                            </Grid>

                            <Grid item xs={3}>
                                <Link to="/dashboard/settings/users">
                                    <Button
                                        type="submit"
                                        variant="contained"
                                    >
                                        Cancel
                                    </Button>
                                </Link>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={disabled}
                                >
                                    Update
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default Preferences
