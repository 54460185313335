import React, { useEffect, useState } from 'react'
import {
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Typography
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import axios from 'axios'
import { API_URL, CODE_DEBUG, TINY_EDITOR_API_KEY } from '../../config'
import ltrim from 'validator/lib/ltrim'
import rtrim from 'validator/lib/rtrim'
import { Editor } from '@tinymce/tinymce-react'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const UpdateHelp = (props) => {
    const helpId = props.match.params.helpId
    const token = localStorage.getItem('tgToken')
    const [content, setContent] = useState('')
    const [initialContent, setInitialContent] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)

    const getData = () => {
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/help'
        axios.get(url, headerConfig).then(response => {
            setContent(response.data.content)
            setInitialContent(response.data.content)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        var _content = content
        _content = ltrim(_content)
        _content = rtrim(_content)
        const data = {
            content: _content
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/help/' + helpId
        axios.put(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Help updated.')
            setShowAlert(true)
            setLoading(false)
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleEditorChange = (content) => {
        setContent(content)
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <MLink color="inherit" href="/#/dashboard/help">
                            Help
                        </MLink>
                        <Typography color="textPrimary">Update Help</Typography>
                    </Breadcrumbs>
                </Grid>
                <Grid item >
                    <Link to="/dashboard/help">
                        <Button
                            type="submit"
                            variant="contained"
                        >
                            Back
                        </Button>
                    </Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                        onClick={onSubmit}
                        variant="contained"
                        color="primary"
                        disabled={loading}
                    >
                        Update
                    </Button>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <form onSubmit={onSubmit}>
                <Grid
                    container
                    spacing={3}
                    direction="rows"
                >
                    <Grid item xs={12}>
                        <Editor
                            initialValue={initialContent}
                            apiKey={TINY_EDITOR_API_KEY}
                            init={{
                                height: 700,
                                menubar: false,
                                plugins: [
                                    'advlist autolink lists link image',
                                    'charmap print preview anchor help',
                                    'searchreplace visualblocks code',
                                    'insertdatetime media table paste wordcount'
                                ],
                                toolbar:
                                    'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | help'
                            }}
                            onEditorChange={handleEditorChange}
                        />
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default UpdateHelp
