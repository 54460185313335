import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    Button,
    ButtonGroup,
    Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    TextField,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../components/Table'
import axios from 'axios'
import { API_URL, CODE_DEBUG } from '../../config'
import AddAlertIcon from '@material-ui/icons/AddAlert'
import DeleteIcon from '@material-ui/icons/Delete'
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn'
import { Link } from 'react-router-dom'
import { dateFormate } from '../../functions'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ListBusinessTickets = (props) => {
    const businessId = props.match.params.businessId
    const token = localStorage.getItem('tgToken')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [open, setOpen] = useState(false)
    const [itemId, setItemId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [openNotificationDialog, setOpenNotificationDialog] = useState(false)
    const [openDeliveredDialog, setOpenDeliveredDialog] = useState(false)

    const [text, setText] = useState('')

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "ticket_id",
            label: "Ticket ID",
            options: {
                sort: true,
            }
        },
        {
            name: "created_at",
            label: "Ticket Received Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "glass_order_no",
            label: "Glass Order No",
            options: {
                sort: true,
            }
        },
        {
            name: "customer_po_no",
            label: "Customer PO No",
            options: {
                sort: true,
            }
        },
        {
            name: "glass_type",
            label: "Glass Type",
            options: {
                sort: false,
            }
        },
        {
            name: "warranty_reason",
            label: "Warranty Reason",
            options: {
                sort: false,
            }
        },
        {
            name: "glass_size",
            label: "Glass Size",
            options: {
                sort: true,
            }
        },
        {
            name: "panel_status",
            label: "Panel Status",
            options: {
                sort: false,
            }
        },
        {
            name: "received_date",
            label: "Glass Received Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "expected_date",
            label: "Expected Replacement Date",
            options: {
                sort: true,
                customBodyRender: dateFormate
            }
        },
        {
            name: "business_name",
            label: "Business",
            options: {
                sort: false,
            }
        },
        {
            name: "name",
            label: "Customer",
            options: {
                sort: false,
            }
        },
        {
            name: "status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: (value, tableMeta, updateValue) => {
                    let status
                    if (value === 'Created') {
                        status = <Chip label={value} style={{ backgroundColor: "#FFF6E2", color: "orange" }} />
                    } else if (value === 'Notified') {
                        status = <Chip label={value} style={{ backgroundColor: "#dae9f2", color: "#0c69a9" }} />
                    } else if (value === 'Delivered') {
                        status = <Chip label={value} style={{ backgroundColor: "#E2FAED", color: "#39D980" }} />
                    } else {
                        status = value
                    }
                    return status
                }
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search,
            disabled: 0,
            business: businessId
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = API_URL + '/tickets'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                const viewUrl = '/dashboard/tickets/' + item.id
                const customerUrl = '/dashboard/customers/' + item.customer_id
                const ticket_id = (
                    <Tooltip title="View Ticket">
                        <Link to={viewUrl} className="link">
                            {item.ticket_id}
                        </Link>
                    </Tooltip>
                )
                const customer = (
                    <Tooltip title="View Customer">
                        <Link to={customerUrl} className="link">
                            {item.name}
                        </Link>
                    </Tooltip>
                )
                let delivered = ''
                if (item.status !== 'Delivered') {
                    delivered = (
                        <Tooltip title="Mark as Delivered">
                            <IconButton onClick={() => handleClickOpenDeliveredDialog(item.id)}>
                                <AssignmentTurnedInIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    )
                }
                const action = (
                    <ButtonGroup>
                        <Tooltip title="Send Notification">
                            <IconButton onClick={() => handleClickOpenNotificationDialog(item.id)}>
                                <AddAlertIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        {delivered}
                        <Tooltip title="Disable Ticket">
                            <IconButton onClick={() => handleClickOpen(item.id)}>
                                <DeleteIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </ButtonGroup>
                )
                if (item.business_id !== '') {
                    const businessUrl = '/dashboard/business/' + item.business_id
                    const business = (
                        <Tooltip title="View Business">
                            <Link to={businessUrl} className="link">
                                {item.business_name}
                            </Link>
                        </Tooltip>
                    )
                    item.business_name = business
                }
                item.ticket_id = ticket_id
                item.name = customer
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const deleteItem = (itemId) => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/tickets/' + itemId
        axios.delete(url, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Deleted.')
            setShowAlert(true)
            setOpen(false)
            getList()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const sendNotification = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            id: itemId,
            message: text
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/tickets/notification'
        axios.post(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Sent.')
            setShowAlert(true)
            setLoading(false)
            handleClose()
            getList(start, limit, sortBy, order, search)
        }).catch(error => {
            var message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 404) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const markAsDelivered = (e) => {
        e.preventDefault()
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/tickets/' + itemId + '/status'
        axios.put(url, {}, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Updated')
            setShowAlert(true)
            setLoading(false)
            handleClose()
            getList(start, limit, sortBy, order, search)
        }).catch(error => {
            var message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 404) {
                    message = error.response.data.detail
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleTextChange = (e) => {
        const value = e.target.value
        setText(value)
    }


    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const handleClickOpen = (itemId) => {
        setOpen(true)
        setItemId(itemId)
    }

    const handleClickOpenNotificationDialog = (itemId) => {
        setOpenNotificationDialog(true)
        setItemId(itemId)
    }

    const handleClickOpenDeliveredDialog = (itemId) => {
        setOpenDeliveredDialog(true)
        setItemId(itemId)
    }

    const handleClose = () => {
        setOpen(false)
        setOpenNotificationDialog(false)
        setOpenDeliveredDialog(false)
        setItemId(null)
        setText('')
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <MLink color="inherit" href="/#/dashboard/business">
                            Business
                        </MLink>
                        <Typography color="textPrimary">Tickets</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Disable Ticket"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to disable the Ticket?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined" autoFocus>
                        Cancel
                    </Button>
                    <Button onClick={() => deleteItem(itemId)} disabled={loading} variant="outlined">
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                open={openNotificationDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={sendNotification}>
                    {progress}
                    <DialogTitle id="alert-dialog-title">Send Notification</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <TextField
                                fullWidth
                                id="outlined-multiline-static"
                                label="Notification Content"
                                required
                                multiline
                                rows={4}
                                inputProps={{ minLength: 3, maxLength: 150 }}
                                variant="outlined"
                                onChange={handleTextChange}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" autoFocus>
                            Close
                        </Button>
                        <Button variant="outlined" type="submit" disabled={loading}>
                            Send
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            <Dialog
                fullWidth
                open={openDeliveredDialog}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={markAsDelivered}>
                    {progress}
                    <DialogTitle id="alert-dialog-title">Mark Ticket As Delivered</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            Are you sure you want to mark the Ticket as Delivered?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" autoFocus>
                            Close
                        </Button>
                        <Button variant="outlined" type="submit" disabled={loading}>
                            Yes
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
            {progress}
            <Table
                title={'List Business Tickets'}
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
        </div>
    )
}

export default ListBusinessTickets
