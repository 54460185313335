import React, { useState } from 'react'
import {
    Breadcrumbs,
    Link as MLink,
    Tab,
    Tabs,
    Paper,
    Typography
} from '@material-ui/core'

import UserDetails from './UserDetails'
import ChangePassword from './ChangePassword'
import Preferences from './Preferences'

const Profile = () => {
    const [value, setValue] = useState(0)
    const [content, setContent] = useState(<UserDetails />)
    const role = localStorage.getItem('tgUserRole');

    const handleChange = (event, newValue) => {
        let content;
        if (newValue === 0) {
            content = <UserDetails />;
        } else if (newValue === 1) {
            content = <ChangePassword />;
        } else {
            if (role === "Super Admin") {
                content = <Preferences />;
            }
        }
        setContent(content)
        setValue(newValue)
    }

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <Typography color="textPrimary">Profile</Typography>
            </Breadcrumbs>
            <br />
            <Paper square >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                >
                    <Tab label="Personal Information" />
                    <Tab label="Change Password" />
                    {role === "Super Admin" && <Tab label="Preferences" />}

                </Tabs>
            </Paper>
            {content}
        </div>
    )
}

export default Profile
