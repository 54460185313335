import { Chip } from '@material-ui/core'
import moment from 'moment'

export function dateToString(date) {
    return date.format('yyyy-MM-DD')
}

export function stringToDate(date) {
    return moment(date, 'yyyy-MM-DD')
}

export function stringToDateTime(date) {
    return moment(date, 'yyyy-MM-DD hh:mm:ss')
}

export function dateFormate(date) {
    const mDate = stringToDate(date)
    return mDate.format('DD/MM/yyyy')
}

export function dateTimeFormate(date) {
    const mDate = stringToDateTime(date)
    return mDate.format('DD/MM/yyyy hh:mm A')
}

export function trolleyStatus(value) {
    let status
    if (value === 'OnPlant') {
        status = <Chip label={'Tough Glass'} className="chip-orange" />
    } else if (value === 'Maintenance') {
        status = <Chip label={value} className="chip-blue" />
    } else if (value === 'DropOff') {
        status = <Chip label={value} className="chip-green" />
    } else if (value === 'Delivery') {
        status = <Chip label={value} className="chip-purple" />
    } else if (value === 'Pickup') {
        status = <Chip label={value} className="chip-cyan" />
    } else if (value === 'DeFleet') {
        status = <Chip label={value} className="chip-red" />
    } else {
        status = <Chip label={value} />
    }
    return status
}

export function checkDateIsExpired(date) {
    const mDate = stringToDate(date)
    const now = moment()
    if (mDate.isBefore(now)) {
        return true
    } else {
        return false
    }
}