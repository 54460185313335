import React, { useState, useEffect } from 'react'
import {
    Breadcrumbs,
    ButtonGroup,
    Grid,
    IconButton,
    LinearProgress,
    Link as MLink,
    Snackbar,
    Tooltip,
    Typography
} from '@material-ui/core'
import Table from '../../../components/Table'
import axios from 'axios'
import { API_URL, CODE_DEBUG } from '../../../config'
import { Link } from 'react-router-dom'
import { dateTimeFormate, trolleyStatus } from '../../../functions'
import MuiAlert from '@material-ui/lab/Alert'
import VisibilityIcon from '@material-ui/icons/Visibility'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const ViewTrolley = (props) => {
    const trolleyId = props.match.params.trolleyId
    const token = localStorage.getItem('tgToken')
    const [tableData, setTableData] = useState([])
    const [count, setCount] = useState(0)
    const [start, setStart] = useState(0)
    const [limit, setLimit] = useState(10)
    const [sortBy, setSortBy] = useState('all')
    const [order, setOrder] = useState('all')
    const [search, setSearch] = useState('all')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [status, setStatus] = useState(null)
    const [uid, setUid] = useState(null)

    const columns = [
        {
            name: "#",
            label: "#",
            options: {
                sort: false,
                customBodyRenderLite: (rowIndex) => {
                    return start + rowIndex + 1
                }
            }
        },
        {
            name: "created_at",
            label: "Date",
            options: {
                sort: true,
                customBodyRender: dateTimeFormate
            }
        },
        {
            name: "customer",
            label: "Customer Name",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    let item = null

                    if (value) {
                        const customerUrl = '/dashboard/customers/' + value.id
                        item = (
                            <Tooltip title="View Customer">
                                <Link to={customerUrl} className="link">
                                    {value.first_name + ' ' + value.last_name}
                                </Link>
                            </Tooltip>
                        )
                    } else {
                        item = 'At ToughGlass'
                    }
                    return item
                }
            }
        },
        {
            name: "no_of_bars",
            label: "No of Bars",
            options: {
                sort: true,
            }
        },
        {
            name: "trolley_status",
            label: "Status",
            options: {
                sort: false,
                customBodyRender: trolleyStatus
            }
        },
        {
            name: "user",
            label: "Update By",
            options: {
                sort: true,
                customBodyRender: (value) => {
                    return value.first_name + ' ' + value.last_name
                }
            }
        },
        {
            name: "time_duration",
            label: "Time Duration",
            options: {
                sort: true,
            }
        },
        {
            name: "action",
            label: "Action",
            options: {
                sort: false,
            }
        }
    ]

    const getList = (start = 0, limit = 10, sortBy = 'all', order = 'all', search = 'all') => {
        setLoading(true)
        const params = {
            sort_by: sortBy,
            order,
            start,
            limit,
            search,
            disabled: 0
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            },
            params
        }
        const url = API_URL + '/trolleys/' + trolleyId + '/transactions'
        axios.get(url, headerConfig).then(response => {
            response.data.list.map(item => {
                // table_data.map(item => {
                const viewUrl = trolleyId + '/' + item.id
                const action = (
                    <ButtonGroup>
                        <Tooltip title="Details">
                            <Link to={viewUrl} className="link">
                                <IconButton>
                                    <VisibilityIcon fontSize="small" />
                                </IconButton>
                            </Link>
                        </Tooltip>
                    </ButtonGroup>
                )
                item.action = action
                return true
            })
            setCount(response.data.count)
            setTableData(response.data.list)
            // setCount(table_data.length)
            // setTableData(table_data)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setLoading(false)
        })
    }

    const getData = () => {
        setLoading(true)
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
                token
            }
        }
        const url = API_URL + '/trolleys/' + trolleyId
        axios.get(url, headerConfig).then(response => {
            const item = response.data
            setUid(item.uid)
            setStatus(item.trolley_status)
            setLoading(false)
        }).catch(error => {
            if (error.response) {
                if (error.response.status === 401) {
                    setAlertType('error')
                    setAlertMessage(error.response.data.detail)
                    setShowAlert(true)
                }
                CODE_DEBUG && console.log(error.response)
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setLoading(false)
        })
    }

    useEffect(() => {
        getData()
        getList()
        // eslint-disable-next-line
    }, [])

    const changePage = (page) => {
        const start = limit * (page)
        getList(start, limit, sortBy, order, search)
        setStart(start)
    }

    const sort = (sortOrder) => {
        const sortBy = sortOrder.name
        const order = sortOrder.direction
        getList(start, limit, sortBy, order, search)
        setSortBy(sortBy)
        setOrder(order)
    }

    const changeRowsPerPage = (limit) => {
        getList(start, limit, sortBy, order, search)
        setLimit(limit)
    }

    const onSearch = (search) => {
        getList(start, limit, sortBy, order, search)
        setSearch(search)
    }

    const handleTableChange = (action, tableState) => {
        switch (action) {
            case 'changePage':
                changePage(tableState.page)
                break
            case 'sort':
                sort(tableState.sortOrder)
                break
            case 'changeRowsPerPage':
                changeRowsPerPage(tableState.rowsPerPage)
                break
            case 'search':
                const search = (tableState.searchText === null) ? 'all' : tableState.searchText
                onSearch(search)
                break
            default:
        }
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <Breadcrumbs aria-label="breadcrumb">
                        <MLink color="inherit" href="/#/dashboard">
                            Dashboard
                        </MLink>
                        <MLink color="inherit" href="/#/dashboard/trolleys">
                            Trolley
                        </MLink>
                        <Typography color="textPrimary">Transactions</Typography>
                    </Breadcrumbs>
                </Grid>
            </Grid>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Grid container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
            >
                <Grid item >
                    <h3>Trolley FSDS: {uid}</h3>
                </Grid>
                <Grid item >
                    <h3>Current Status : {status && trolleyStatus(status)}</h3>
                </Grid>
            </Grid>
            {progress}
            <Table
                title={'List Trolley'}
                serverSide={true}
                count={count}
                columns={columns}
                data={tableData}
                onTableChange={handleTableChange}
                rowsPerPage={limit}
            />
        </div>
    )
}

export default ViewTrolley
