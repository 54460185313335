import React, { useEffect, useState } from 'react'
import {
    Card,
    CardContent,
    Breadcrumbs,
    Button,
    Grid,
    LinearProgress,
    Link as MLink,
    TextField,
    Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom'
import { apiGet, apiPut } from '../../../functionsAPI';
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'
import { Autocomplete } from '@material-ui/lab'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

const EditTrolley = (props) => {
    const trolleyId = props.match.params.trolleyId
    const [name, setName] = useState('')
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')
    const [loading, setLoading] = useState(false)
    const [plants,setPlants] = useState([])
    const [plant,setPlant] = useState(null)
    const [toPlant,setToPlant] = useState(null)
    const [note,setNote] = useState('')
    
    const [status,setStatus] = useState(null)

    const getData = () => {
        setLoading(true)

        const url = '/trolleys/' + trolleyId
        apiGet(url)
        .then(response => {
            const item = response.data
            setName(item.name)
            setStatus(item.trolley_status)
            setPlant(item.plant)
            getPlants(item.plant.id)
            setLoading(false)

        }).catch(error => {
            console.log(error)
            setAlertType('error')
            setAlertMessage(error)
            setShowAlert(true)
        })
    }

    const getPlants = (id) => {      
        const url = '/plant/all'
        apiGet(url)
        .then(response => {
            const data = []
            response.data.map((item) => {
                const found =  item.id === id                
                if (!found) return data.push(item);
                else return null                
            })
            setPlants(data)
        })
        .catch(error => {
            console.log(error)
            setAlertType('error')
            setAlertMessage(error)
            setShowAlert(true)
        })
    }

    useEffect(() => {
        getData()
        // eslint-disable-next-line
    }, [])

    const handleNameChange = (e) => {
        var value = e.target.value
        setName(value)
    }

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const _note = note ? note : ''
        const _from_plant_id = plant ? plant.id : null
        const _to_plant_id = toPlant ? toPlant.id : null
        const data = {
            name : null,
            no_of_bars: 0,
            images: [],
            image: null,
            note: _note,
            to_plant_id: _to_plant_id,
            from_plant_id: _from_plant_id,
        }
        const url = '/trolleys/' + trolleyId + '/plantchange'
        apiPut(url, data)
        .then(response => {
            setAlertType('success')
            setAlertMessage('Updated.')
            setShowAlert(true)
            setLoading(false)
            props.history.push('/dashboard/trolleys')
        }).catch(error => {
            console.log(error)
            setAlertType('error')
            setAlertMessage(error)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    const progress = loading ? (<LinearProgress />) : ('')

    return (
        <div>
            <Breadcrumbs aria-label="breadcrumb">
                <MLink color="inherit" href="/#/dashboard">
                    Dashboard
                </MLink>
                <MLink color="inherit" href="/#/dashboard/trolleys">
                    Trolleys
                </MLink>
                <Typography color="textPrimary">Transfer Trolley</Typography>
            </Breadcrumbs>
            <br />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            {progress}
            <Card>
                <CardContent>
                    <form onSubmit={onSubmit}>
                        <Grid item xs={12} md={4} className="mt-5">
                            <TextField
                                label="Name"
                                variant="outlined"
                                inputProps={{ minLength: 2, maxLength: 36 }}
                                value={name}
                                onChange={handleNameChange}
                                fullWidth
                                required />
                            <br /><br />
                            <TextField
                                disabled
                                label="Current Plant"
                                variant="outlined"
                                fullWidth
                                value={plant ? plant.name : ''}
                            />
                             <br /><br />
                             <TextField
                                disabled
                                label="Status"
                                variant="outlined"
                                fullWidth
                                value={status ? status : ''}
                            />
                             <br /><br />
                            <Autocomplete
                                options={plants}
                                value={toPlant}
                                autoHighlight
                                getOptionLabel={(option) => option.name }
                                renderInput={(params) => <TextField
                                    required
                                    {...params}
                                    label="To plant"
                                    variant="outlined"
                                />}
                                onChange={(event, value) => setToPlant(value)}
                            />
                            <br /><br />
                            <TextField
                                label="Note"
                                multiline
                                variant="outlined"
                                fullWidth
                                value={note ? note : ''}
                                onChange={(e)=> setNote(e.target.value)}
                            />
                             <br /><br />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Link to="/dashboard/trolleys"><Button variant="contained">Cancel</Button></Link>
                            <Button variant="contained" color="primary" className="ml-2" type="submit" disabled={loading}>
                                Transfer
                            </Button>
                        </Grid>
                    </form>
                </CardContent>
            </Card>
        </div>
    )
}

export default EditTrolley
