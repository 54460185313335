import React, { useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Link from '@material-ui/core/Link'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Grid from '@material-ui/core/Grid'

import axios from 'axios'
import {API_URL, CODE_DEBUG} from '../config'

import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />
}

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.toughglass.com.au/">
                2015 - {new Date().getFullYear()} ToughGlass
            </Link>
            {' '}|{' '}
            <Link color="inherit" href="/">
                Home
            </Link>
        </Typography>
    )
}

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
    },
    main: {
        marginTop: theme.spacing(8),
        marginBottom: theme.spacing(2),
    },
    footer: {
        padding: theme.spacing(3, 2),
        marginTop: 'auto',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[800],
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}))

export default function Support() {
    const classes = useStyles()
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [description, setDescription] = useState('')
    const [loading, setLoading] = useState(false)
    const [showAlert, setShowAlert] = useState(false)
    const [alertType, setAlertType] = useState('success')
    const [alertMessage, setAlertMessage] = useState('')

    const onSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        const data = {
            name,
            email,
            description
        }
        const headerConfig = {
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }
        const url = API_URL + '/support'
        axios.post(url, data, headerConfig).then(response => {
            setAlertType('success')
            setAlertMessage('Success')
            setShowAlert(true)
            setLoading(false)
            setName('')
            setEmail('')
            setDescription('')
            e.target.reset()
        }).catch(error => {
            let message = 'Try after some time.'
            if (error.response) {
                if (error.response.status === 401) {
                    message = error.response.data.detail
                } else if (error.response.status === 422) {
                    if (error.response.data.detail.length > 0) {
                        message = error.response.data.detail[0].loc[1] + ' ' + error.response.data.detail[0].msg
                    } else {
                        message = error.response.data.detail.msg
                    }
                }
            } else if (error.request) {
                CODE_DEBUG && console.log(error.request)
            } else {
                CODE_DEBUG && console.log(error)
            }
            setAlertType('error')
            setAlertMessage(message)
            setShowAlert(true)
            setLoading(false)
        })
    }

    const handleNameChange = (e) => {
        var value = e.target.value
        setName(value)
    }

    const handleEmailChange = (e) => {
        var value = e.target.value
        setEmail(value)
    }

    const handleDescriptionChange = (e) => {
        var value = e.target.value
        setDescription(value)
    }

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowAlert(false);
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                open={showAlert}
                autoHideDuration={5000}
                onClose={handleAlertClose}
            >
                <Alert onClose={handleAlertClose} severity={alertType}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Container component="main" className={classes.main} maxWidth="lg">
                <Typography variant="h2" component="h1" gutterBottom>
                    Support
                </Typography>
                <br />
                <form className={classes.form} onSubmit={onSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                autoComplete="name"
                                name="firstName"
                                variant="outlined"
                                required
                                fullWidth
                                id="firstName"
                                label="Name"
                                inputProps={{ minLength: 3, maxLength: 150}}
                                autoFocus
                                onChange={handleNameChange}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                id="email"
                                label="Email Address"
                                name="email"
                                autoComplete="email"
                                inputProps={{ minLength: 3, maxLength: 150, type: 'email' }}
                                onChange={handleEmailChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                required
                                fullWidth
                                multiline
                                id="email"
                                label="Support Description"
                                name="email"
                                autoComplete="email"
                                inputProps={{ rows: 5, minLength: 3, maxLength: 250 }}
                                onChange={handleDescriptionChange}
                            />
                        </Grid>
                    </Grid>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        disabled={loading}
                    >
                        Submit
                    </Button>
                </form>
            </Container>
            <footer className={classes.footer}>
                <Container maxWidth="sm">
                    <Copyright />
                </Container>
            </footer>
        </div>
    )
}
